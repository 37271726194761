<template>
  <div class="tickets">
    <div class="absolute top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12" />
    <div class="px-4 md:px-10 mx-auto w-full relative -mt-11">
      <HelpBar @getData="getData" />
      <div class="flex flex-wrap mt-4">
        <SlideUp>
          <ListTable
            :tableName="$t('tickets')"
            :headers="headers"
            :tableData="filteredTickets"
            :loading="$store.state.tickets.loading"
            createName="NewTicket"
          >
            <!-- qrType="ticket" -->
            <template v-slot:top-right-corner>
              <div class="flex mx-3">
                <div
                  v-for="statusType in statusTypes"
                  :key="statusType"
                  class="mr-2 mx-1 px-2 border rounded cursor-pointer font-semibold"
                  @click="selectStatusFilter = statusType"
                  :class="{
                    'bg-gray-700 text-gray-50': selectStatusFilter == statusType
                  }"
                >
                  {{ $t(statusType) }}
                </div>
              </div>
              <router-link :to="{ name: 'NewTicket' }">
                <button
                  class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                >
                  {{ $t("add new") }}
                </button>
              </router-link>
            </template>
          </ListTable>
        </SlideUp>
      </div>
    </div>
  </div>
</template>

<script>
import SlideUp from "@/components/transitions/SlideUp.vue";
import ListTable from "@/components/ListTable.vue";
import formatting from "../mixins/formatting";
import { mapState } from "vuex";
import HelpBar from "../components/HelpBar.vue";
// import PopoverWrapper from "@/components/PopoverWrapper.vue";

export default {
  name: "Tickets",
  components: {
    SlideUp,
    HelpBar,
    ListTable
    // PopoverWrapper
  },
  mixins: [formatting],
  computed: {
    ...mapState("tickets", ["tickets"]),
    filteredTickets() {
      if (this.selectStatusFilter == "all tickets") {
        return this.tickets;
      }
      return this.tickets.filter(ticket => {
        let showClosed = true;
        if (this.selectStatusFilter == "open") showClosed = false;
        return ticket.is_closed == showClosed;
      });
      // .filter(ticket => ticket.supplier_id in this.selectedSuppliers);
    }
  },
  data() {
    return {
      selectedSuppliers: {},
      statusTypes: ["open", "closed", "all tickets"],
      selectStatusFilter: "open",
      headers: [
        {
          name: "#",
          key: "id",
          type: "link",
          linkInfo: {
            name: "Ticket-Detail",
            routeKey: "id",
            varKey: "id"
          }
        },
        // { name: "status", key: "is_closed", type: "is_closed", filter: true },
        { name: "status", key: "is_closed", type: "is_closed" },
        { name: "subject", key: "subject", type: "" },
        { name: "opened", key: "inserted_at", type: "fromNowDate" },
        {
          name: "type",
          key: "type.name",
          type: "",
          filter: true
        },
        { name: "due date", key: "due_date", type: "dueDate" },
        { name: "supplier", key: "supplier.name", type: "", filter: true },
        { name: "asset", key: "bike.name", type: "", filter: true },
        { name: "damages", key: "damages", type: "damages", filter: true },
        { name: "hub", key: "hub.name", type: "", filter: true },
        { name: "labels", key: "tags", type: "labels", filter: true },
        {
          name: "actions",
          key: "action",
          type: "action",
          noSort: true,
          actions: [
            {
              name: "delete",
              icon: "far fa-trash-alt",
              action: (evt, ticket) => {
                evt.stopPropagation();

                const confirmStr = `${this.$t("Confirm delete ticket")}: ${
                  ticket.subject
                }`;
                this.$confirm(confirmStr).then(() => {
                  this.$store.dispatch("tickets/deleteTicket", {
                    ticket
                  });
                });
              }
            }
          ]
        }
      ]
    };
  },
  methods: {
    getData() {
      this.$store.dispatch("tickets/getTickets");
    },
    removeSupplier(supplier) {
      this.$delete(this.selectedSuppliers, supplier.id);
    },
    selectSupplier(supplier) {
      if (supplier.id in this.selectedSuppliers) {
        this.removeSupplier(supplier);
      } else {
        this.$set(this.selectedSuppliers, supplier.id, supplier);
      }
    }
  },
  created() {
    this.$store.dispatch("hubs/getAllHubs", hubs => {
      this.$store.commit(
        "SET_HUBS",
        hubs.map(({ id }) => id)
      );
      this.getData();
    });
  }
};
</script>
