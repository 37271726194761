<template>
  <div class="">
    <div class="fixed top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12 z-0" />
    <div class="flex px-8 md:px-14 mt-10 flex-wrap">
      <SlideUp class="w-full lg:w-2/3">
        <Form
          :form="formData"
          formName="new Ticket"
          :options="options"
          :lastPageName="'Ticket'"
        />
      </SlideUp>
    </div>
  </div>
</template>

<script>
import ticketform from "../mixins/ticketform";
import SlideUp from "@/components/transitions/SlideUp.vue";
import Form from "@/components/Form.vue";

export default {
  name: "NewTicket",
  mixins: [ticketform],
  components: {
    SlideUp,
    Form
  },
  // computed: {
  //   ...mapState("typeDefinitions", ["ticketDefinitions"]),
  //   ...mapState("suppliers", ["suppliers"]),
  //   ...mapState("hubs", ["hubs"]),
  //   ...mapState("bikes", ["bikes"]),
  //   ...mapState("users", ["users"]),
  //   options() {
  //     return {
  //       ticketDefinitions: this.ticketDefinitions,
  //       suppliers: this.suppliers,
  //       hubs: this.hubs,
  //       bikes: this.bikes,
  //       users: this.users
  //     };
  //   }
  // },
  data() {
    return {
      formData: [
        {
          section: "Ticket Information",
          dispatch: "tickets/createTicket",
          fields: [
            { key: "id", name: "id", type: "hidden", value: "" },
            {
              key: "subject",
              name: "title",
              type: "text",
              value: "",
              width: "full",
              required: true
            },
            {
              name: "description",
              key: "description",
              type: "textareaEditor",
              width: "full",
              value: ""
            },
            {
              key: "due_date",
              name: "due date",
              type: "datepicker",
              value: ""
            },
            {
              name: "types",
              key: "type_id",
              value: "",
              type: "selectByOptionProp",
              options: "ticketDefinitions",
              optionLabelKey: "name"
            },
            {
              name: "supplier",
              key: "supplier_id",
              value: "",
              type: "selectByOptionProp",
              options: "suppliers",
              optionLabelKey: "name"
            },
            {
              name: "recipients",
              key: "users",
              value: [],
              type: "multiselectByOptionProp",
              options: "users",
              optionLabelKey: "name"
            },
            {
              name: "hub",
              key: "hub_id",
              value: "",
              type: "selectByOptionProp",
              options: "hubs",
              optionLabelKey: "name"
            },
            {
              name: "asset",
              key: "bike_id",
              value: "",
              type: "selectByOptionProp",
              options: "bikes",
              optionLabelKey: "name"
            }
          ]
        }
      ]
    };
  }
  // created() {
  //   this.$store.dispatch("typeDefinitions/getAllTicketDefinitions");
  //   this.$store.dispatch("suppliers/getAllSuppliers");
  //   this.$store.dispatch("users/getAllUsers");
  //   this.$store.dispatch("bikes/getAllBikes");
  //   this.$store.dispatch("hubs/getAllHubs");
  // }
};
</script>
